import React from "react"
import PropTypes from "prop-types"
import { graphql } from "gatsby"
import renderLegos from "lib/renderLegos"
import Layout from "components/Layout"
import ProductsStack from "components/ProductsStack"

const LegoPage = ({
  data: {
    page: { title, content },
  },
}) => <Layout title={title}>{legos(content)}</Layout>

export default LegoPage

LegoPage.propTypes = {
  data: PropTypes.shape({
    page: PropTypes.shape({
      title: PropTypes.string,
      content: PropTypes.arrayOf(PropTypes.object).isRequired,
    }).isRequired,
  }).isRequired,
}

const legos = content =>
  renderLegos(
    content
      .reduce((output, entry) => {
        if (Object.keys(stackableTypes).includes(entry._type)) {
          if (
            Object.values(stackableTypes).includes(output.slice(-1)[0]?._type)
          ) {
            output.slice(-1)[0].stack.push(entry)
          } else {
            output.push({ _type: stackableTypes[entry._type], stack: [entry] })
          }
        } else {
          output.push(entry)
        }

        return output
      }, [])
      .map(entry =>
        // Flatten out stackable types with only a single entry
        entry.stack?.length === 1
          ? entry.stack[0]
          : entry.stack
          ? { ...entry, _key: entry.stack.map(({ _key }) => _key).join("-") }
          : entry
      ),
    { serializers }
  )

const stackableTypes = {
  productsLego: "productsStack",
}

const serializers = {
  ProductsStack,
}

export const query = graphql`
  query PageQuery($_id: String!) {
    page: sanityLegoPage(_id: { eq: $_id }) {
      title
      content {
        ...Legos
      }
      ...PageMetadata
    }
  }
`
