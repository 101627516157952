import React, { useEffect, useRef } from "react"
import PropTypes from "prop-types"
import SanityImage from "gatsby-plugin-sanity-image"
import { AnimateSharedLayout, motion } from "framer-motion"
import imageProptypes from "lib/imageProptypes"
import InvisibleButton from "components/InvisibleButton"
import StickyNavBar from "components/StickyNavBar"
import ProductsLego from "legos/ProductsLego"
import theme from "theme"

const ProductsStack = ({ stack, index }) => {
  const [activeItem, setActiveItem] = React.useState(stack[0]._key)
  const [targets, setTargets] = React.useState([])

  const refs = useRef({}).current
  for (let i = 0; i < stack.length; i++) {
    // We’re always calling useRef in the same order as long as the number of
    // LEGOs doesn't change, which is a safe assumption in production.
    // eslint-disable-next-line react-hooks/rules-of-hooks
    refs[i] = useRef()
  }

  // Allow stable access to the _keys of the LEGOs in the effect hook (i.e.
  // avoid object dependencies with changing identity)
  const keyStr = stack.map(item => item._key).join(",")

  // Measure tops of product LEGOs and “cache” for efficient onScroll checking
  useEffect(() => {
    const keys = keyStr.split(",")

    const onResize = () => {
      setTargets(
        Object.values(refs)
          .map((ref, index) => ({
            key: keys[index],
            top: ref.current.getBoundingClientRect().top + window.scrollY,
          }))
          .sort((a, b) => b.top - a.top)
      )
    }

    onResize()
    window.addEventListener("resize", onResize)

    return () => {
      window.removeEventListener("resize", onResize)
    }
  }, [keyStr, refs])

  // Update active LEGO based on pre-computed top positions
  useEffect(() => {
    const onScroll = () => {
      const target =
        targets.find(target => target.top <= window.scrollY + 225) ||
        targets.slice(-1)[0]

      setActiveItem(target.key)
    }

    window.addEventListener("scroll", onScroll)

    return () => {
      window.removeEventListener("scroll", onScroll)
    }
  }, [targets])

  return (
    <div>
      <StickyNavBar
        css={{
          position: "sticky",
          top: 0,
          background: theme.tan,
          padding: "0 30px",
          [theme.smol]: {
            padding: "0 20px",
          },
        }}
      >
        <nav
          css={{
            display: "grid",
            gridTemplateColumns: "repeat(4, minmax(15%, max-content))",
            gap: 30,
            justifyContent: "space-between",
            alignItems: "center",
            width: 700,
            maxWidth: "100%",
            margin: "0 auto",
            [theme.max(450)]: {
              display: "flex",
              gap: "unset",
            },
          }}
        >
          <AnimateSharedLayout>
            {stack.map(({ _key, productType }) => (
              <InvisibleButton
                key={_key}
                onClick={() => {
                  document
                    .getElementById(productType.slug.current)
                    ?.scrollIntoView({ behavior: "smooth", block: "start" })
                }}
                css={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  position: "relative",
                  padding: "20px 0",
                  [theme.max(550)]: {
                    padding: "14px 0",
                  },
                }}
              >
                <SanityImage
                  {...productType.icon}
                  width={48}
                  height={48}
                  alt=""
                  css={{
                    display: "block",
                    width: 48,
                    [theme.max(550)]: { width: 33, height: 33 },
                    [theme.smol]: { width: 28, height: 28 },
                  }}
                />

                <div css={{ ...theme.h5, marginTop: 5 }}>
                  {productType.name}
                </div>

                {activeItem === _key && (
                  <motion.div
                    initial={false}
                    layoutId="products-stack-active-indicator"
                    css={{
                      position: "absolute",
                      bottom: 0,
                      width: "100%",
                      height: 5,
                      background: theme.stampRed,
                    }}
                  />
                )}
              </InvisibleButton>
            ))}
          </AnimateSharedLayout>
        </nav>
      </StickyNavBar>

      {stack.map((legoProps, index) => (
        <ProductsLego key={legoProps._key} ref={refs[index]} {...legoProps} />
      ))}
    </div>
  )
}

export default ProductsStack

ProductsStack.propTypes = {
  stack: PropTypes.arrayOf(
    PropTypes.shape({
      _key: PropTypes.string.isRequired,
      _type: PropTypes.string.isRequired,
      productType: PropTypes.shape({
        name: PropTypes.string.isRequired,
        slug: PropTypes.shape({
          current: PropTypes.string.isRequired,
        }).isRequired,
        icon: imageProptypes.isRequired,
      }).isRequired,
    }).isRequired
  ).isRequired,
  index: PropTypes.number.isRequired,
}
